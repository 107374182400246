import { useEffect, useState } from 'react';
import { ElementRenderer } from '../Basic';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import LangContext from '../LangContext';
import './IntroPage.sass';

function IntroPage() {
    // const userLang = (navigator.language || navigator.userLanguage) == 'zh-TW' ? 'ch' : 'en'; 
    const userLang = 'en'; 
    const storedLang = localStorage.getItem('lang');
    const [lang, setLang] = useState(storedLang ?? userLang);
    const [content, setContent] = useState(null);

    function updateLang(lang) {
        setLang(lang);
        localStorage.setItem('lang', lang);
    }

    useEffect(() => {
        if (process.env.NODE_ENV !== "production")
            // Inject fake data
            setTimeout(_ => {
                setContent(
                    [
                        {
                            type: "professor_info",
                            config: {
                                fill: "light"
                            }
                        }, {
                            type: "professor_biography"
                        }, {
                            type: "professor_publications"
                        }, {
                            type: "professor_detail",
                            config: {
                                biography: true,
                                research: true
                            }
                        }
                    ]
                );
            }, 200);
        else
            fetch(`/api/intro.json?lang=${lang}`)
                .then(res => {
                    if (res.ok) return res.json();
                    throw new Error('Broke intro.json request');
                })
                .then(json => setContent(json))
                .catch(e => console.error(e));
    }, [lang]);

    return (
        <LangContext.Provider value={lang}>
            <div className='content'>
                <ElementRenderer
                    active={content != null}
                    contents={content}
                />
            </div>
            <Navbar updateLang={updateLang} />
            <Footer />
        </LangContext.Provider>
    );
}

export default IntroPage;
